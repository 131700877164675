<template>
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>订单列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-table class="a-table" :data="tableData" @row-click="handleDetailShow">
      <el-table-column label="订单号" prop="orderId">
        <template slot-scope="scope">
          <span style="cursor: pointer">{{ scope.row.orderId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="模型" prop="model" />
      <el-table-column label="数量" prop="quantity" />
      <el-table-column label="用户" prop="user" />
      <el-table-column label="服务商" prop="servicer" />
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag size="small" :type="types[scope.row.state]">{{
            scope.row.state ? status[scope.row.state] : ""
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createDate" />
    </el-table>
    <!-- 分页 -->
    <div class="a-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="layout"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 订单详情 -->
    <el-dialog
      :before-close="handleDetailClose"
      :visible.sync="visible.detail"
      title="订单详情"
      width="480px"
      :destroy-on-close="true"
    >
      <el-row class="a-detail-item"
        ><el-col :span="8">订单号：</el-col
        ><el-col :span="16">{{ orderInfo.orderId }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">状态：</el-col
        ><el-col :span="16"
          ><el-tag size="small" :type="types[orderInfo.state]">{{
            orderInfo.state ? status[orderInfo.state] : ""
          }}</el-tag></el-col
        ></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">模型：</el-col
        ><el-col :span="16">{{ orderInfo.model }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">数量：</el-col
        ><el-col :span="16">{{ orderInfo.quantity }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">材料：</el-col
        ><el-col :span="16">{{ orderInfo.material }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">成型原理：</el-col
        ><el-col :span="16">{{ orderInfo.forming }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">服务商：</el-col
        ><el-col :span="16">{{ orderInfo.servicer }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">打印时限：</el-col
        ><el-col :span="16">{{
          orderInfo.date + " " + orderInfo.time
        }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">最大返工个数：</el-col
        ><el-col :span="16">{{ orderInfo.reworkTime }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">备注：</el-col
        ><el-col :span="16">{{
          orderInfo.note ? orderInfo.note : "无"
        }}</el-col></el-row
      >
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      form: {},
      visible: {
        detail: false,
      },
      status: this.$store.state.status,
      types: this.$store.state.types,
      orderInfo: {},
      /**分页 */
      pageSizes: [20, 50, 100],
      pageSize: 20,
      layout: "total, sizes, prev, pager, next, jumper",
      currentPage: 1,
      total: 1,
    };
  },
  methods: {
    /**订单详情 */
    handleDetailShow(row) {
      // console.log(row, col, e);
      this.orderInfo = row;
      this.visible.detail = true;
    },
    handleDetailClose() {
      this.orderInfo = "";
      this.visible.detail = false;
    },
    /**分页 */
    // 页面尺寸改变
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    // 当前页改变
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.a-detail-item {
  margin: 0.5rem 0;
}
.a-detail-operate {
  margin-top: 2rem;
  text-align: center;
}
</style>
